:root {
  --blu-text: #192841;
  --beige-dark: black;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  max-width: 100%;
  font-family: "Helvetica Neue";
}


.full-height-container {
  height: 100vh;
}

.home-background {
  background-image: url("assets/landscape.png");
  /*background-image: url("assets/Bilder/portraits/portraitlandscape.jpg");*/

  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}

.section-header {
  padding-top: 30px;
  padding-bottom: 0;
  font-size: 26px;
  text-align: center;
  color: var(--blu-text);
}


.text-card-container {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;

  padding-bottom: 20px;
  text-align: justify;
  color: var(--beige-dark);
  font-size: 1.1rem;
  width: 100%;

  /*background-color: black;*/
}

.subscribe-form-container {
  background-color: #eeeeee;
  margin-left: 40px;
  margin-right: 40px;
  padding: 20px 40px 20px;
  text-align: center;

}

.subscribe-form-container input {
  /*width: auto;*/
  height: 50px;
  margin: 10px;
  /*text-align: center;*/
}

.subscribe-form-container button {
  width: auto;
  height: 50px;
  margin: 10px;
  padding: 10px 30px 10px;
  background-color: #2A2829;
  color: white;
  border: none;
}

.text-card-container-quote {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;
  text-align: center;
  font-style: italic;
}

.text-card-container th {
  padding-top: 15px;
  padding-bottom: 8px;
  color: var(--blu-text);
}

/*
PORTFOLIO
*/

.work-box {
  margin-bottom: 3rem;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fff;
  padding-left: 15px;
  padding-right: 15px;
}

.work-box:hover img {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.work-img {
  display: block;
  overflow: hidden;
}

.work-img img {
  transition: all 1s;
}

.work-content {
  padding-top: 10px;
  text-align: left;
  color: var(--beige-dark);
}

.work-content, a:link {
  text-decoration: none;
}

.work-content .w-more {
  font-size: .9rem;
}

.work-content .w-more .w-ctegory {
  /*color: #0078ff;*/
}

.work-content .w-like {
  font-size: 2.5rem;
  color: #0078ff;
  float: left;

}

.work-content .w-like a {
  color: #0078ff;
}

.work-content .w-like .num-like {
  font-size: .7rem;
}

.w-title {
  font-size: 1.2rem;
  text-decoration: none;
}

.nav-icon img {
  height: 35px;
}

.links-middle {
  /*height: 10vh;*/
  width: 50%;
  display: flex;
  align-items: center;
  position: sticky;
  top: 10vh;
  z-index: 1;
  font-size: 19px;

}

.links-middle img {
  background-color: #ffffff;
  height: 10vh
}

.col-sm-12 table td {
  padding-right: 15px; /* Adjust the value as needed */
}

.impressum {
  font-size: 12px
}

.image-copyright {
  font-size: 12px;
  color: grey;
}